.container{
    opacity: 0.3;
    user-select: none;
}

.container:hover{
    opacity: 1;
}


.team_circle{
    width: 10px;
    height: 10px;
    border-radius: 10px;
}

p{
    margin: 0;
}

.scores{
    display: flex;
    justify-content: space-between;
}

.scores > div > p{
    margin: 0 10px;
}
.scores > div{
    display: flex;
    align-items: center;
}